import { Link } from 'gatsby'
import React from 'react'

export default function Port() {
  return (
    <>
    <section id="clients" className="section-bg">

      <div className="container">

        <div className="section-header">
          <h3>Initiatives  for start-ups Summary</h3>
        </div>

        <div className="row g-0 clients-wrap clearfix" data-aos="zoom-in" data-aos-delay="100">

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <Link to="#">
                <img src="/assets/img/programs/programs1.png" className="img-fluid" alt="" />
              </Link>

            </div>
          </div>

          <div className="col-lg-9 col-md-4 col-xs-6">
            <div className="client-logo1">
              <div class="card-body">
                <h4 class="card-title programe-details">XIP – Xccelerata Intellectual Property Initiatives </h4>
                <p class="card-text pg-text">This includes acquiring patents, trademarks, and other legal entities needed to protect your intellectual property.</p>

              </div>
            </div>
          </div>


          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <Link to="#">
                <img src="/assets/img/programs/programs2.png" className="img-fluid" alt="" />
              </Link>
            </div>
          </div>

          <div className="col-lg-9 col-md-4 col-xs-6">
            <div className="client-logo1">
            <div class="card-body">
                <h4 class="card-title programe-details">XIP – Xccelerata Intellectual Property Initiatives </h4>
                <p class="card-text pg-text" >This includes acquiring patents, trademarks, and other legal entities needed to protect your intellectual property.</p>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <Link to="#">
                <img src="/assets/img/programs/programs3.png" className="img-fluid" alt="" />
              </Link>
            </div>
          </div>

          <div className="col-lg-9 col-md-4 col-xs-6">
            <div className="client-logo1">
            <div class="card-body">
                <h4 class="card-title programe-details">XGM - Xccelerata Growth Mentorship Initiatives </h4>
                <p class="card-text">We provide an extensive network of industry and domain experts with the knowledge, application experience, and leadership to ensure your company's success.</p>

              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <Link to="#">
                <img src="/assets/img/programs/programs4.png" className="img-fluid" alt="" />
              </Link>
            </div>
          </div>

          <div className="col-lg-9 col-md-4 col-xs-6">
            <div className="client-logo">
            <div class="card-body">

                <h4 class="card-title programe-details">XPE - Xccelerata Partner Ecosystem Initiatives </h4>
                <p class="card-text pg-text">This includes acquiring patents, trademarks, and other legal entities needed to protect your intellectual property.</p>

              </div>

            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <Link to="#">
                <img src="/assets/img/programs/programs5.png" className="img-fluid" alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-9 col-md-4 col-xs-6">
            <div className="client-logo">
            <div class="card-body">
                <h4 class="card-title programe-details">XTA – Xccelarata Talent Acquisition Initiatives </h4>
                <p class="card-text pg-text">There is nothing more important to a technology-based company than talented and motivated people. Our XTA Program, finds and attracts the right people to help grow your business into a successful global enterprise.</p>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <Link to="#">
                <img src="/assets/img/programs/programs6.png" className="img-fluid" alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-9 col-md-4 col-xs-6">
            <div className="client-logo">
            <div class="card-body">
                <h4 class="card-title programe-details">XCA – Xccelerata Customer Acquisition Initiatives </h4>
                <p class="card-text pg-text">Our XCA Program is designed to help you develop and execute effective marketing strategies and develop product innovations to drive continued customer acquisition, sales, and profits.</p>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <Link to="#">
                <img src="/assets/img/programs/programs7.png" className="img-fluid" alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-9 col-md-4 col-xs-6">
            <div className="client-logo">
            <div class="card-body">
              
                <h4 class="card-title programe-details">XOS – Xccelerata Ongoing Support Initiatives </h4>
                <p class="card-text pg-text">We know that many start-up companies lack the resources for technological support. We offer a specific program to support your company's transition to a self-supporting capability.</p>

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="client-logo">
              <Link to="#">
                <img src="/assets/img/programs/programs8.png" className="img-fluid" alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-9 col-md-4 col-xs-6">
            <div className="client-logo">
            <div class="card-body">
                <h4 class="card-title programe-details">XFA – Xccelerata Funding Acquisition Initiatives </h4>
                <p class="card-text pg-text">If you have a minimally viable product, and have early customer interest and validation, we can help you find funding for your business through our XFA Program and our extensive network of angel investors, government programs, institutions, corporations, and venture capitalists.</p>

              </div>
            </div>
          </div>

        </div>
        <br />
        {/* <div className="row row-eq-height justify-content-center">
            <div className="col-lg-4 mb-4">
              <center>
                <Link to="/partnerwithus" className="btn lg-page-button">
                Become a Partner
                </Link>
              </center>
            </div>
          </div> */}

      </div>
    </section>
    </>
  )
}
