import React from "react"
import "../styles/logo.css"
import "../components/Layout"
import Navigation from "../components/Navigation"
import Includes from "../components/Includes"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Port from "../components/Porgram Details"







export default function programs() {
  return (<>
            <Header/>
            <Navigation/>
            <br></br>
            <Port/>
            <Includes/>
            <Footer/>
  </>)
}